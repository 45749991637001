window.initMap = function(branches, icon) {

  const centerPosition = {lat: 51.509406,lng: -0.1782765}
  const showAll = branches.length > 1 

  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: showAll ? 13 : 16,
    center: centerPosition,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true
  });

  for (const [key, branch] of Object.entries(branches)) {
    if (!showAll) map.setCenter(branch.coords)
    const marker = new google.maps.Marker({
      position: branch.coords,
      title: branch.name,
      map: map,
      icon: icon
    });
    let contentString = '<h2>'+branch.name+'</h2><p>'+branch.address+'</p>';
    if (branch.showLink) {
      contentString += '<p><a href="/location/'+branch.key+'">View</a>'; 
    } else {
      contentString+='<p>Opening autumn</p>'
    }
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });
  }
}

